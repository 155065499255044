.container {
    background-color: var(--white-color);
    height: 310px;
    flex: 0.5;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.titleRow {
    padding: 20px 30px 20px 30px;
}

.orderRow {
    padding: 9px 30px 9px 30px;
    border-top: 1px solid var(--light-grey-color);
}

.orderWithIconContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.font {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
}

.ordersTitleText {
    color: var(--grey-color-50);
}

.seeAllText {
    color: var(--blue-color);
}

.orderText {
    color: black;
    padding-left: 18px;
}