.rotateImage {
    transform: rotate(90deg);
    margin-right: 30px;
}

.dropdownContent {
    margin-top: 5px;
    margin-right: 20px;
    margin-left: auto;
    border-bottom: 2px solid var(--silver-color);
    background-color: var(--light-grey-color);
    width: 40%;
    transform: translateY(-30px);
}

.trigger {
    border: none;
    background-color: transparent;
    position: relative;
}


.flexContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    column-gap: 30px;
    cursor: pointer;
}

.dropItemText {
    padding-left: 20px;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
}

.dropItemIcon {
    padding-right: 20px;
}