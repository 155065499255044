.cardsContainer {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    gap: 20px;
}

.cardsContainer > * {
    width: 100%;
}

.defaultFigureCaption {
    margin: 0;
    padding: 0;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.defaultFigureCaption > * {
    margin: 0;
    padding: 0;
}

.row {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.row > * {
    margin: 0;
    padding: 0;
}