.status {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--grey-color);
    border-radius: 100px;
    height: 25px;
    width: 100px;
    flex-shrink: 0;
}

.statusText {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--grey-color-50);
}