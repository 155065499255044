.background {
    background-color: var(--light-grey-color);
    height: 100%;
    width: auto;
    border-left: 1px solid var(--grey-color);
}

.dashboardMainContentContainer {
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 40px;
    min-height: calc(100vh - var(--page-topbar-height) - var(--footer-height) - 40px);
}

.columnFlex {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.rowFlex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.greyText {
    color: var(--text-disabled-color);
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
}

.twoCardsFlexContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}