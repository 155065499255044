.container {
    border-bottom: 1px solid var(--light-grey-color);
    display: block;
    padding: 10px 30px 10px 30px;
}

.container:hover {
    background-color: var(--grey-very-light);
}

.notificationText {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: var(--dark-grey-color);
}

.bold {
    font-weight: bold;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    gap: 5px;
}

.callToActionLabel {
    color: var(--white-color);
    background-color: var(--blue-color);
    display: inline-flex;
    align-items: center;
    justify-items: space-between;
    gap: 20px;
    height: 30px;
    border-radius: 100px;
    padding: 0 16px;
    font-size: 13px;
    font-weight: 500;
}

.arrowIcon {
    transform: rotate(180deg);
}
