.container {
    background-color: var(--white-color);
    height: 380px;
    flex: 0.5;
}

.content {
    margin: 30px;
}

.graphTitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: var(--black-color);
}

.row {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    gap: 10px;
}

.rowTitle {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}

.rowDropdown {
    min-width: 150px;
}

.legendMarkup {
    width: 7px;
    height: 7px;
    border-radius: 2px;
}

.legendMarkup.blue {
    background-color: var(--blue-color);
}

.legendMarkup.black {
    background-color: var(--black-color);
}

.legendText {
    color: var(--grey-color-50);
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.legendGap {
    margin-left: 10px;
}

.tooltipLabel {
    display: none;
    width: 0;
    height: 0;
}

.tooltip {
    font-size: 10px;
    font-weight: 700;
    padding: 0 5px 0 5px;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: var(--white-color);
    border-radius: 5px;
    box-shadow: 5px 5px 5px rgb(0,0,0, 0.1);
    width: 120px;
    height: 100px;
}

.vrRateTooltip {
    color: var(--blue-color);
}

.referenceLabel {
    color: var(--black-color);
    font-size: 9px;
    font-weight: 700;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 2px;
    width: 70px;
    height: 15px;
    box-shadow: 0 2px 4px 0 rgba(6, 46, 82, 0.25);
}

.tooltipButton {
    max-height: 18px;
}

.rowWithTabs {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
}

.tab {
    border: none;
    color: var(--grey-color-50);
    padding: 6px 0 6px 0;
    border-radius: 3px;
    font-size: 12px;
    line-height: normal;
    font-weight: 700;
    cursor: pointer;
    width: 30px;
    text-align: center;
    gap: 5px;
}

.tab.active {
    color: var(--white-color);
    background-color: var(--black-color);
}

.tab.inactive {
    background-color: transparent;
    opacity: 0.5;
}

.tab.inactive:hover {
    background-color: var(--grey-color);
}

@media (max-width: 1440px) {

    .container {
        background-color: var(--white-color);
        height: 380px;
        flex:0.5 !important;
    }

}