.comingSoonContainer {
    background-color: white;
    margin-top: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--light-grey-color);
    height: 80px;
}

.titleText {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: var(--black-color);
    padding: 30px 0 30px 30px;
}

