.notificationsContainer {
    background-color: white;
    flex: 0.5;
    height: 380px;
    margin-left: 20px;
}

.notificationText {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: var(--grey-color-50);
    padding: 30px 0 30px 30px;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border-bottom: 1px solid var(--light-grey-color);
}

.notificationTitleText {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: var(--grey-color-50);
    padding: 20px 0 20px 30px;
}

.scroll {
    height: 260px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.scroll::-webkit-scrollbar-track {
    background-color: var(--grey-color);
}

.scroll::-webkit-scrollbar {
    width: 4px;
}

.scroll::-webkit-scrollbar-thumb {
    background-color: var(--grey-color-50);
}

.item {
    position: absolute;
    top: 0;
}

.dropdown {
    position: absolute;
}

.rotateImage {
    transform: rotate(90deg);
    margin-right: 30px;
}