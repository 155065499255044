.container {
    min-height: var(--footer-height);
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: var(--grey-color);
}

.text {
    margin-left: 40px;
    color: var(--black-color);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
}

.textBold {
    margin-left: 40px;
    color: var(--black-color);
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
}

.rowWidthPadding {
    padding-right: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.nonDisplay {
    opacity: 0;
}
