.button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    border-radius: 100px;
    gap: 8px;
    padding: 0 18px;
}

.notExistingOrderButton {
    background-color: var(--blue-color);
}

.existingOrderButton {
    background-color: var(--black-color);
    flex-direction: row-reverse;
}

.text {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: var(--white-color);
    padding: 10px 0 10px 0;
}